@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: #1f1e21;
}

@font-face {
	font-family: Stargaze;
	src: url("./assets/shared/fonts/stargaze.otf");
}
